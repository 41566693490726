import michal from '@images/workers/michal.webp';
import { phoneNumberMichal } from '@utils/variables';

const nbsp = '\u00A0';

export const pageData = {
  id: 1,
  title: `Finansowanie`,
  // subtitle: `Prowadzimy szkolenia i procesy HR dla naszych Klientów:`,
  slug: `finansowanie`,
  worker: {
    employee: `Michał Kmieć`,
    employeePhone: `${phoneNumberMichal}`,
    employeePhoto: michal,
  },
  description: {
    content: `Pomagamy w uzyskaniu kredytów dla Klientów indywidualnych oraz biznesowych. Współpracujemy ze wszystkimi bankami działającym na polskim rynku oraz dodatkowo instytucjami pozabankowymi. Nasze usługi obejmują cały proces kredytowy dzięki czemu oszczędzasz czas i${nbsp}pieniądze oraz ograniczasz ryzyka.`,
    list: [
      `W naszej ofercie znajdziesz:`,
      `kredyty hipoteczne`,
      `kredyty firmowe`,
      `kredyty gotówkowe`,
      `kredyty samochodowe`,
      `leasing samochodów, maszyn i urządzeń`,
      `factoring`,
      `pożyczki`,
    ],
  },
};

export default {};
