import { pageData } from '@utils/subpages-data/finansowanie';
import React from 'react';

import Subpage from './_subpage';

const Financing = () => {
  return <Subpage data={pageData} />;
};

export default Financing;
