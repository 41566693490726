import Container from '@components/Container';
import Layout from '@components/Layout';
import LayoutTopSection from '@components/LayoutTopSection';
import Paragraph from '@components/Typography/Paragraph';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const Img = styled.img`
  max-width: 100%;
  max-height: 30rem;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ImgBox = styled.div`
  display: flex;
  justify-content: flex-end;
  background: var(--footer-background);
  box-shadow: 0 0 10px black;
  position: relative;
  margin: 0 0 2rem 0;
`;

const ImgCard = styled.div`
  height: 100%;
  width: 100%;
  background: var(--main-gradient);
  box-shadow: 0 0 10px black;
  position: absolute;
  z-index: -1;
  top: 1rem;
  left: 1rem;
`;

const Li = styled.li`
  margin: 0 0 0.5rem 0;
  line-height: 1.3;

  &:first-child {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }
  :not(:first-child) {
    :before {
      content: '■';
      display: block;
      float: left;
      width: 1.2em;
      color: #bd8604;
    }
  }
`;

const P = styled.p`
  line-height: 1.5;
  margin: 0 0 1.5rem 0;
  text-align: justify;
`;

const Subpage = ({ data }) => {
  const { title, worker, description, slug } = data;
  const { employee, employeePhone, employeePhoto } = worker;
  const { content, list } = description;

  const renderSiteItem = () => {
    return (
      <FlexColumn>
        {employeePhoto && (
          <ImgBox>
            <Img src={employeePhoto} alt={employee} />
            <ImgCard />
          </ImgBox>
        )}
        <Paragraph>{employee}</Paragraph>
        {employeePhone && (
          <Paragraph>
            Telefon:
            {` `}
            <a href={`tel:${employeePhone}`}>{employeePhone}</a>
          </Paragraph>
        )}
      </FlexColumn>
    );
  };

  const renderContent = () => {
    return (
      <div>
        {content && <P>{content}</P>}
        {list && (
          <ul>
            {list.map((item) => {
              return <Li key={`${slug}-${item}`}>{item}</Li>;
            })}
          </ul>
        )}
      </div>
    );
  };

  const renderLayout = () => {
    return (
      <Layout pageTitle={title}>
        <Container>
          <LayoutTopSection
            showRightSideOnMobile
            title={title}
            subtitle={data.subtitle && data.subtitle}
            content={(content || list) && renderContent()}
            rightSide={(employee || employeePhone || employeePhoto) && renderSiteItem()}
          />
        </Container>
      </Layout>
    );
  };

  return renderLayout();
};

Subpage.propTypes = {
  data: PropTypes.shape({}).isRequired,
};

export default Subpage;
